/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import LogoutPage from "./Logout";
import "../../index.css";
/// Image
import profile from "../../../images/profile/pic1.jpg";
import { useLanguage } from "../../../context/LanguageContext";
import translations from "../../../translation/translation";
import { getDecodedRefreshTokenFromLocalStorage } from "../../../jwt/jwtDecoder";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  const decodedToken = getDecodedRefreshTokenFromLocalStorage("userDetails");
  const userName = decodedToken.payload.username;

  const { language } = useLanguage();
  const t = translations[language];

  let projects = [
      "List Projects",
      "Budgets",
      "Gantt chart",
      "Labor Demand List",
    ],
    myTasks = ["Kanban Board", "Pending tasks"],
    employee = ["Employee general list ", "Timesheet", "Payroll"],
    finance = ["treasure"],
    treasure = ["bank", "cash"],
    dashboards = ["Statistics", "Analytical reports"];
  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          <Dropdown as="li" className="nav-item dropdown header-profile">
            <Dropdown.Toggle
              variant=""
              as="a"
              className="nav-link i-false c-pointer"
              // href="#"
              role="button"
              data-toggle="dropdown"
            >
              <img src={profile} width={20} alt="" />
              <div className="header-info ms-3 ps-0 text-truncate">
                <span
                  className="font-w600 d-inline-block text-truncate mb-0"
                  title={userName}
                  style={{ maxWidth: "150px" }}
                >
                  {t.hi}, <b>{userName}</b>
                </span>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu
              align="right"
              className="mt-2 dropdown-menu dropdown-menu-end"
            >
              <Link to="/app-profile" className="dropdown-item ai-icon">
                <svg
                  id="icon-user1"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-warning"
                  width={18}
                  height={18}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                  <circle cx={12} cy={7} r={4} />
                </svg>
                <span className="ms-2">{t.profile} </span>
              </Link>
              <Link to="/invoice" className="dropdown-item ai-icon">
                <svg
                  id="icon-inbox"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-success"
                  width={18}
                  height={18}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                  <polyline points="22,6 12,13 2,6" />
                </svg>
                <span className="ms-2">{t.invoices}</span>
              </Link>
              <Link to="/company-list" className="dropdown-item ai-icon">
                <svg
                  id="icon-company-list"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-primary"
                  width={18}
                  height={18}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
                  <path d="M8 6h8M8 10h4M8 14h8" />
                  <path d="M4 21v-2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v2" />
                  <path d="M9 21v-4" />
                  <path d="M15 21v-4" />
                </svg>
                <span className="ms-2">{t.companyList}</span>
              </Link>

              <LogoutPage />
            </Dropdown.Menu>
          </Dropdown>
          <li className={`${projects.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-381-layer-1"></i>
              <span className="nav-text">{t.projects}</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${
                    path === "projects" ? "mm-active" : "projects"
                  }`}
                  to="/projects"
                >
                  {" "}
                  {t.listProjects}
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "budgets" ? "mm-active" : "budgets"}`}
                  to="/budgets"
                >
                  {" "}
                  {t.budgets}
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path === "gantt-chart" ? "mm-active" : "gantt-chart"
                  }`}
                  to="/gantt-chart"
                >
                  {" "}
                  {t.ganttChart}
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path === "labor-demand" ? "mm-active" : "labor-demand"
                  }`}
                  to="/labor-demand"
                >
                  {" "}
                  {t.laborDemand}
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${myTasks.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-381-list-1"></i>
              <span className="nav-text">{t.myTasks}</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${
                    path === "kanban-board" ? "mm-active" : "kanban-board"
                  }`}
                  to="/kanban-board"
                >
                  {" "}
                  {t.kanbanBoard}
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path === "pending-tasks" ? "mm-active" : "pending-tasks"
                  }`}
                  to="/pending-tasks"
                >
                  {" "}
                  {t.pendingTasks}
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${employee.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-381-user-4"></i>
              <span className="nav-text">{t.employee}</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "employee-list" ? "mm-active" : ""}`}
                  to="/employee-list"
                >
                  {t.employeeGeneralList}
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path === "timesheet" ? "mm-active" : "timesheet"
                  }`}
                  to="/timesheet"
                >
                  {" "}
                  {t.timesheet}
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "payroll" ? "mm-active" : "payroll"}`}
                  to="/payroll"
                >
                  {" "}
                  {t.payroll}
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${finance.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-050-info"></i>
              <span className="nav-text">{t.finance}</span>
            </Link>
            <ul>
              <li className={`${treasure.includes(path) ? "mm-active" : ""}`}>
                <Link className="" to="/treasury">
                  {t.treasury}
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${dashboards.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-025-dashboard"></i>
              <span className="nav-text">{t.dashboards}</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "" ? "mm-active" : "statistics"}`}
                  to="/statistics"
                >
                  {" "}
                  {t.statistics}
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    path === "" ? "mm-active" : "analytical-reports"
                  }`}
                  to="/analytical-reports"
                >
                  {" "}
                  {t.analyticalReports}
                </Link>
              </li>
            </ul>
          </li>
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
