const translations = {
  en: {
    projects: "Projects",
    addBudget: "Add budget",
    editBudget: "Edit budget",
    budgetDetails: "Budget details",
    budgets: "Budgets",
    gantt: "Gantt Chart",
    laborDemand: "Labor Demand List",
    kanbanBoard: "Kanban Board",
    pendingTasks: "Pending tasks",
    employeeList: "Employee List",
    employeeInformation: "Employee Information",
    addEmployee: "Add Employee",
    timesheet: "Timesheet",
    addTimesheet: "Add Timesheet",
    payroll: "Payroll",
    addPayroll: "Add Payroll",
    details: "Details",
    treasury: "Treasury",
    statistics: "Statistics",
    analyticalReports: "Analytical Reports",
    profile: "Profile",
    invoice: "Invoice",
    companyList: "Company List",
    dashboard: "Dashboard",
  },
  tr: {
    projects: "Projeler",
    budgets: "Bütçeler",
    addBudget: "Bütçe Ekle",
    editBudget: "Bütçeyi Düzenle",
    budgetDetails: "Bütçe Ayrıntıları",
    gantt: "Gantt Grafiği",
    laborDemand: "İşgücü Talep Listesi",
    kanbanBoard: "Kanban Panosu",
    pendingTasks: "Bekleyen Görevler",
    employeeList: "Personel Listesi",
    addEmployee: "Personel Ekle",
    employeeInformation: "Çalışan Bilgileri",
    timesheet: "Zaman Çizelgesi Listesi",
    addTimesheet: "Zaman Çizelgesi Ekle",
    payroll: "Bordro",
    addPayroll: "Bordroyu Ekle",
    details: "Detaylar",
    treasury: "Hazine",
    statistics: "İstatistikler",
    analyticalReports: "Analitik Raporlar",
    profile: "Profil",
    invoice: "Fatura",
    companyList: "Şirket Listesi",
    dashboard: "Kontrol Paneli",
  },
  ru: {
    projects: "Проекты",
    budgets: "Бюджеты",
    addBudget: "Добавить бюджет",
    editBudget: "Изменить бюджет",
    budgetDetails: "Детали бюджета",
    gantt: "Диаграмма Гантт",
    laborDemand: "Список потребностей в рабочей силе",
    kanbanBoard: "Канбан доска",
    pendingTasks: "Ожидающие задачи",
    employeeList: "Список сотрудников",
    addEmployee: "Добавить сотрудника",
    employeeInformation: "Информация о сотруднике",
    timesheet: "Список расписаний",
    addTimesheet: "Добавить расписание",
    payroll: "Платежная ведомость",
    addPayroll: "Добавить платежную ведомость",
    details: "Детали",
    treasury: "Казначейства",
    statistics: "Статистика",
    analyticalReports: "Аналитические отчеты",
    profile: "Профиль",
    invoice: "Счет",
    companyList: "Список компаний",
    dashboard: "Панель управления",
  },
};

export default translations;
